var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_c('h1',[_vm._v("Мои заказы")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({name:'orders-new'})}}},[_vm._v("Новый заказ")])],1)],1),(_vm.items && _vm.items.length)?_c('v-row',[_c('v-col',[(_vm.items)?_c('v-expansion-panels',{staticClass:"mt-4",attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.nonEmptyPeriods),function(period,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(_vm._s(period.NAME))]),_c('v-expansion-panel-content',[(_vm.items)?_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.itemsOfPeriod(period),"hide-default-footer":"","items-per-page":-1,"item-class":_vm.itemClass},on:{"click:row":_vm.onSelectOrder},scopedSlots:_vm._u([{key:"item.DELIVERY_DATE",fn:function(ref){
var item = ref.item;
return [(item.CONFIRMED_DELIVERY_DATE)?_c('span',[_vm._v(_vm._s(item.CONFIRMED_DELIVERY_DATE))]):_c('span',[_vm._v(_vm._s(item.DELIVERY_DATE))])]}},{key:"item.STATUS",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(item),"dark":"","width":"100px","small":""}},[_vm._v(_vm._s(item.STATUS)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nf")(_vm.total(item)))+" ")]}},{key:"item.totalConfirmed",fn:function(ref){
var item = ref.item;
return [(_vm.totalConfirmed(item))?_c('span',[_vm._v(_vm._s(_vm._f("nf")(_vm.totalConfirmed(item))))]):_vm._e()]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.onSelectOrder(item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)}):_c('p',[_vm._v("Нет заказов в периоде...")])],1)],1)}),1):_vm._e()],1)],1):_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('p',[_vm._v("Пока нет заказов...")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }